import * as THREE from "three";
import { DragControls } from "three/examples/jsm/controls/DragControls";
import { toRaw } from "vue";
import API from "@/api/API";
import {
  MOUNTING_SURFACE_COLOR,
  SOLAR_POINT_COLOR,
  RENDERING_ORDER,
} from "../constants";

export const hideAreas = function () {
  this.areas
    .filter((area) => area.plane)
    .forEach((area) => {
      area.plane.visible = false;
      area.points.forEach((point) => (point.visible = false));
      area.lines.forEach((line) => {
        line.line.visible = false;
        line.midPoint.visible = false;
      });
    });
};

export const showAreas = function () {
  this.areas
    .filter((area) => area.plane)
    .forEach((area) => {
      area.plane.visible = true;
      if (area.panels.length === 0) {
        area.points.forEach((point) => (point.visible = true));
        area.lines.forEach((line) => {
          line.line.visible = true;
          line.midPoint.visible = true;
        });
      }
    });
};

export const removeUnfinshedArea = function () {
  const currentArea = this.areas[this.areas.length - 1];
  if (this.areas.length > 0 && !currentArea.closed) {
    const points = currentArea.points;
    this.removePoints(points);
    this.removeSnapIcon(false);

    if (this.measurementAreaEndingLine) {
      this.removeObjectFromScene(this.measurementAreaEndingLine);
      this.measurementAreaEndingLine = null;
    }
    this.removeDashedLine();

    if (currentArea.lines) {
      this.removeArrayFromScene(currentArea.lines.map((line) => line.line));
      currentArea.lines
        .map((line) => line.midPoint)
        .forEach((point) => {
          this.removeObjectWithChildrenFromScene(point);
        });
    }

    this.areas = this.areas.slice(0, this.areas.length - 1);
  }
};

export const updateTransparencyLevel = function (area, value) {
  area.panels.forEach((panel) => {
    const material = panel.plane.material;
    material.opacity = value / 100;
    material.needsUpdate = true;
  });
};

export const stickMousePointerToDot = function (event) {
  // disable point placing when clicking outside the model
  if (event.target.tagName !== "CANVAS") return;

  this.setMousePosition(event);

  if (
    this.areas.length === 0 ||
    this.areas[this.areas.length - 1].points.length === 0
  )
    return;

  let intersects = this.raycaster.intersectObject(this.modelObject.children[0]);
  if (intersects.length < 1) return;
  let o = intersects[0];
  let pIntersect = o.point.clone();
  this.scene.worldToLocal(pIntersect);

  const currentArea = this.areas[this.areas.length - 1];

  const firstDot = currentArea?.points[0];
  const secondDot = currentArea?.points[1];
  const thirdDot = currentArea?.points[2];
  const lastDot = currentArea?.points[currentArea.points.length - 1];

  const distance = firstDot.position.distanceTo(pIntersect);
  const threshold = 0.25;

  if (firstDot && secondDot && thirdDot) {
    if (distance <= threshold) {
      this.selectedPoint = firstDot;
      this.renderer.domElement.style.cursor = `none`;
      this.areas[this.areas.length - 1].closeArea = true;
      this.selectedPoint.setPointColor(MOUNTING_SURFACE_COLOR);
      this.dashedMeasurementLine.material.color.setHex(MOUNTING_SURFACE_COLOR);
      this.showSnapIcon();

      if (this.measurementAreaEndingLine)
        this.measurementAreaEndingLine.visible = false;
      this.inMagenticField = true;

      const firstPoint = new THREE.Vector3();
      const secondPoint = new THREE.Vector3();
      firstDot.getWorldPosition(firstPoint);
      lastDot.getWorldPosition(secondPoint);
      let points = [firstPoint, secondPoint];
      this.updateLinePosition(this.dashedMeasurementLine, points);
    } else {
      if (this.detectAreaIntersection()) {
        this.renderer.domElement.style.cursor = `pointer`;
      } else {
        this.changeCursorToCrosshair();
      }
      this.areas[this.areas.length - 1].closeArea = false;
      if (this.selectedPoint) {
        this.selectedPoint.setPointColor(SOLAR_POINT_COLOR);
        this.dashedMeasurementLine.material.color.setHex(SOLAR_POINT_COLOR);
        if (this.measurementAreaEndingLine)
          this.measurementAreaEndingLine.visible = true;
        this.inMagenticField = false;
        this.hideSnapIcon();
        this.selectedPoint = null;
      }
    }
  } else {
    if (this.detectAreaIntersection()) {
      this.renderer.domElement.style.cursor = `pointer`;
    } else {
      this.changeCursorToCrosshair();
    }
    if (this.selectedPoint) {
      this.selectedPoint.setPointColor(SOLAR_POINT_COLOR);
      this.dashedMeasurementLine.material.color.setHex(SOLAR_POINT_COLOR);
      if (this.measurementAreaEndingLine)
        this.measurementAreaEndingLine.visible = true;
      this.inMagenticField = false;
      this.selectedPoint = null;
      this.hideSnapIcon();
    }
  }
};

export const addPoint = function (event) {
  event.preventDefault();

  if (this.disableClick(event)) return;

  this.setMousePosition(event);

  let solidLine = null;
  let midPoint = null;
  let oldTempLine = null;
  let oldFirstPoint = null;
  let newFixedLine = null;

  // replace dashed line with solid line
  if (this.areas.length > 0 && !this.areas[this.areas.length - 1].closed) {
    const lastArea = this.areas[this.areas.length - 1];
    oldFirstPoint = lastArea.firstPoint;

    if (lastArea.tempLine) {
      oldTempLine = lastArea.tempLine;
      const solidLinePoints = [
        lastArea.firstPoint.position,
        this.checkForClosedArea(lastArea)
          ? lastArea.points[0].position
          : lastArea.tempPoint.position,
      ];
      solidLine = this.createReactiveThickLine(
        solidLinePoints,
        4.0,
        false,
        false,
        SOLAR_POINT_COLOR
      );

      this.removeDashedLine();

      this.scene.add(solidLine);

      midPoint = this.createReactiveMidPoint(
        lastArea.firstPoint,
        this.checkForClosedArea(lastArea)
          ? lastArea.points[0]
          : lastArea.tempPoint,
        SOLAR_POINT_COLOR
      );
      this.scene.add(midPoint);

      lastArea.tempLine = null;
      if (lastArea.lines) {
        newFixedLine = {
          line: solidLine,
          firstPoint: lastArea.firstPoint,
          secondPoint: this.checkForClosedArea(lastArea)
            ? lastArea.points[0]
            : lastArea.tempPoint,
          midPoint: midPoint,
        };
        lastArea.lines.push(newFixedLine);
      } else {
        newFixedLine = {
          line: solidLine,
          firstPoint: lastArea.firstPoint,
          secondPoint: this.checkForClosedArea(lastArea)
            ? lastArea.points[0]
            : lastArea.tempPoint,
          midPoint: midPoint,
        };
        lastArea.lines = [newFixedLine];
      }
    }
  }

  // check for closed area
  if (
    this.areas.length > 0 &&
    this.areas[this.areas.length - 1].points.length > 0
  ) {
    let pointIntersects = this.raycaster.intersectObject(
      this.areas[this.areas.length - 1].points[0]
    );
    if (
      pointIntersects.length > 0 ||
      this.areas[this.areas.length - 1].closeArea
    ) {
      this.drawPlane(this.areas[this.areas.length - 1].points);
      return;
    }
  }

  // check for duplicate points
  if (
    this.areas.length > 0 &&
    this.areas[this.areas.length - 1].points.length > 0
  ) {
    let pointIntersects = this.raycaster.intersectObjects(
      this.areas[this.areas.length - 1].points
    );
    if (pointIntersects.length > 0) return;
  }

  let intersects = this.raycaster.intersectObject(this.modelObject.children[0]);
  if (intersects.length < 1) return;

  if (
    this.detectAreaIntersection() &&
    this.areas[this.areas.length - 1].closed
  ) {
    this.selectArea(event);
    return;
  }

  // disable marker adding/connecting when drag is on
  if (this.dragOn) return;

  let o = intersects[0];

  let pIntersect = o.point.clone();
  this.scene.worldToLocal(pIntersect);

  const dot = this.createReactivePoint(pIntersect, false, this.isFirstPoint);

  const tempPoint = this.createNonReactiveAreaPoint(
    pIntersect,
    SOLAR_POINT_COLOR
  );

  if (this.areas.length > 0 && !this.areas[this.areas.length - 1].closed) {
    this.areas[this.areas.length - 1].points.push(dot);
  } else {
    this.areas.push({ points: [dot], closed: false });
  }

  let area = this.areas[this.areas.length - 1];

  area.firstPoint = dot;
  area.tempPoint = tempPoint;

  if (area.lines && area.lines[area.lines.length - 1].firstPoint) {
    area.lines[area.lines.length - 1].secondPoint = dot;
  }
  this.camera.lookAt(area.firstPoint.position);

  const firstPoint = new THREE.Vector3();
  const secondPoint = new THREE.Vector3();
  area.firstPoint.getWorldPosition(firstPoint);
  area.tempPoint.getWorldPosition(secondPoint);
  let points = [firstPoint, secondPoint];

  let newLine = this.createReactiveThickLine(
    points,
    4.0,
    true,
    false,
    SOLAR_POINT_COLOR
  );
  this.dashedMeasurementLine = newLine;
  area.tempLine = newLine;

  this.scene.add(dot);

  if (area.points.length > 1)
    // add point undo callback function
    this.undoStack.push({
      action: "ADD_POINT",
      area: area,
      point: dot,
      line: solidLine,
      midPoint: midPoint,
      oldTempLine: oldTempLine,
      oldFirstPoint: oldFirstPoint,
      newTempLine: newLine,
      fixedLine: newFixedLine,
    });
  this.resetRedoStack();
};

export const updatePreliminaryPointPositionForArea = function (intersects) {
  if (
    this.areas.length > 0 &&
    !this.areas[this.areas.length - 1].closed &&
    !this.inMagenticField
  ) {
    if (intersects.length < 1) return;
    let o = intersects[0];
    let pIntersect = o.point.clone();
    this.scene.worldToLocal(pIntersect);
    const area = this.areas[this.areas.length - 1];
    let marker = area.tempPoint;
    marker.position.x = pIntersect.x;
    marker.position.y = pIntersect.y;
    marker.position.z = pIntersect.z;
    const firstPoint = new THREE.Vector3();
    const secondPoint = new THREE.Vector3();
    area.firstPoint.getWorldPosition(firstPoint);
    area.tempPoint.getWorldPosition(secondPoint);
    let points = [firstPoint, secondPoint];

    if (this.dashedMeasurementLine) {
      this.updateLinePosition(this.dashedMeasurementLine, points);
    }

    const line = this.dashedMeasurementLine;
    const lineObject = this.scene.getObjectById(line.id);
    if (!lineObject) this.scene.add(toRaw(line));

    const numPoints = area.points.length;
    if (numPoints > 2) {
      const areaFirstDot = new THREE.Vector3();
      area.points[0].getWorldPosition(areaFirstDot);
      const endingPoints = [areaFirstDot, secondPoint];

      if (this.measurementAreaEndingLine) {
        this.updateLinePosition(this.measurementAreaEndingLine, endingPoints);
      } else {
        let newDottedLine = this.createReactiveThickLine(
          endingPoints,
          4.0,
          true,
          true,
          SOLAR_POINT_COLOR
        );
        this.measurementAreaEndingLine = newDottedLine;
        this.scene.add(newDottedLine);
      }
    }
  }
};

export const selectArea = function (event) {
  event.preventDefault();

  if (event.target.tagName !== "CANVAS") return;

  this.setMousePosition(event);

  let intersects = this.raycaster.intersectObjects(
    this.areas.filter((area) => area.plane).map((area) => area.plane)
  );
  if (intersects.length < 1) return;

  let areaToExpand = intersects[0].object;
  const clickedArea = this.areas.find(
    (area) => area.plane && area.plane.id === areaToExpand.id
  );
  if (this.existingAreasIds.includes(clickedArea.id) && this.anonymousUser) {
    return;
  }
  clickedArea.expanded = true;
  clickedArea.plane.material.opacity = 0.8;
  this.areas
    .filter((area) => area.plane.id !== areaToExpand.id)
    .forEach((area) => (area.plane.material.opacity = 0.5));
  this.selectedArea = clickedArea;

  setTimeout(this.displayFloatingMenuForArea, 10);

  this.isEditButtonDisplayed = this.selectedArea.panels.length > 0;

  document.removeEventListener("click", this.addPoint, false);
  window.removeEventListener("mousemove", this.stickMousePointerToDot);

  document.removeEventListener(
    "mousemove",
    this.detectModelIntersection,
    false
  );

  this.enablePointDragMode();
};

export const selectAreaWithoutClick = function (area) {
  area.expanded = true;
  area.plane.material.opacity = 0.8;

  this.selectedArea = area;

  setTimeout(this.displayFloatingMenuForArea, 10);

  this.isEditButtonDisplayed = this.selectedArea.panels.length > 0;

  document.removeEventListener("click", this.addPoint, false);
  window.removeEventListener("mousemove", this.stickMousePointerToDot);

  document.removeEventListener(
    "mousemove",
    this.detectModelIntersection,
    false
  );

  this.enablePointDragMode();
};

export const closeFloatingMenuForArea = function () {
  if (this.active === 5) {
    this.disablePanelEditMode();
  } else {
    this.disablePointDragMode();
    document.addEventListener("click", this.addPoint, false);
    document.addEventListener("mousemove", this.detectModelIntersection, false);
    window.addEventListener("mousemove", this.stickMousePointerToDot);
  }
  this.selectedArea = null;
  this.areas
    .filter((area) => area.plane)
    .forEach((area) => (area.plane.material.opacity = 0.5));
  this.positionUpdated = false;
};

export const hideAreaPoints = function (area) {
  area.points.forEach((point) => (point.visible = false));
  area.lines.forEach((line) => {
    line.midPoint.visible = false;
    line.line.visible = false;
  });
  document.removeEventListener("click", this.addPoint, false);
};

export const resetPointsColor = function () {
  this.areas[this.areas.length - 1].points.forEach((point) =>
    point.setPointColor(SOLAR_POINT_COLOR)
  );
};

export const createPlaneForSolarArea = function (points) {
  let triangle = new THREE.Triangle(points[0], points[1], points[2]);

  let plane = new THREE.Plane();
  triangle.getPlane(plane);

  return plane;
};

export const projectPointsOnPlane = function (points, plane) {
  const vectorPoints = points.map(
    (point) =>
      new THREE.Vector3(point.position.x, point.position.y, point.position.z)
  );

  const projectedPoints = [];
  for (let i = 0; i < vectorPoints.length; i++) {
    const projectedPoint = new THREE.Vector3();
    plane.projectPoint(vectorPoints[i], projectedPoint);
    projectedPoints.push(projectedPoint);

    points[i].position.x = projectedPoint.x;
    points[i].position.y = projectedPoint.y;
    points[i].position.z = projectedPoint.z;
  }

  const projectedPointsAsArray = projectedPoints.map((point) => [
    point.x,
    point.y,
    point.z,
  ]);

  const flatProjectedPoints = [].concat(...projectedPointsAsArray);

  return flatProjectedPoints;
};

export const projectLinesOnPlane = function (lines, plane) {
  for (let i = 0; i < lines.length; i++) {
    const line = lines[i];

    const firstPoint = new THREE.Vector3();
    const secondPoint = new THREE.Vector3();
    line.firstPoint.getWorldPosition(firstPoint);
    line.secondPoint.getWorldPosition(secondPoint);
    let points = [firstPoint, secondPoint];

    this.updateLinePosition(line.line, points);
    this.updateMidPointPosition(line.midPoint, firstPoint, secondPoint);
  }
};

export const drawPlane = async function (points, createArea = true) {
  this.resetPointsColor();
  this.resetUndoStack();
  this.resetRedoStack();
  this.removeSnapIcon();

  this.removeDashedLine();

  if (this.measurementAreaEndingLine)
    this.removeObjectFromScene(this.measurementAreaEndingLine);

  this.measurementAreaEndingLine = null;

  // Convert points to Vector3 objects
  const vectorPoints = points.map(
    (point) =>
      new THREE.Vector3(point.position.x, point.position.y, point.position.z)
  );

  const areaPlane = this.createPlaneForSolarArea(vectorPoints);

  const projectedPoints = this.projectPointsOnPlane(points, areaPlane);

  const area = this.areas[this.areas.length - 1];
  this.projectLinesOnPlane(area.lines, areaPlane);

  const geometry = new THREE.BufferGeometry();

  const vertices = new Float32Array(projectedPoints);

  const triangleIndices = this.getTriangleIndices(
    points,
    this.getAxisDifferences(points.map((point) => point.position))
  );
  const indices = [].concat(...triangleIndices);

  geometry.setAttribute("position", new THREE.BufferAttribute(vertices, 3));
  geometry.setIndex(new THREE.Uint16BufferAttribute(indices, 1));

  const material = new THREE.MeshBasicMaterial({
    color: MOUNTING_SURFACE_COLOR,
    side: THREE.DoubleSide,
    transparent: true,
    opacity: 0.5,
  });

  const plane = new THREE.Mesh(geometry, material);
  plane.material.depthTest = false;
  plane.renderOrder = RENDERING_ORDER.SOLAR_PLANE;

  this.areas[this.areas.length - 1].plane = plane;
  this.areas[this.areas.length - 1].expanded = false;
  this.areas[this.areas.length - 1].panelSpacing = null;
  this.areas[this.areas.length - 1].transparencyLevel = 100;
  this.areas[this.areas.length - 1].panelType = null;
  this.areas[this.areas.length - 1].panels = [];
  this.areas[this.areas.length - 1].closed = true;
  this.areas[this.areas.length - 1].closeArea = false;
  this.areas[this.areas.length - 1].trianglePlane = areaPlane;
  this.areas[this.areas.length - 1].indices = indices;
  this.areas[this.areas.length - 1].panelMap = new Map();
  this.areas[this.areas.length - 1].angle = this.calculateAreaAngle(
    geometry,
    material
  );

  this.scene.add(plane);
  const areaPoints = points.map((point) => {
    return {
      x: point.position.x,
      y: point.position.y,
      z: point.position.z,
    };
  });
  if (!this.anonymousUser && createArea) {
    try {
      const { data } = await this.createAreaObject(areaPoints);
      this.areas[this.areas.length - 1].id = data;
    } catch (e) {}
  }
  if (createArea)
    this.selectAreaWithoutClick(this.areas[this.areas.length - 1]);
};

export const createAreaObject = async function (points) {
  if (this.sample) return;
  const areaObject = {
    projectId: Number(this.projectId),
    position: points,
  };
  return await API.airteam3DViewer.createAreaObject(areaObject);
};

export const updateAreaObject = async function (id, points) {
  if (this.sample) return;
  const areaObject = {
    id,
    projectId: Number(this.projectId),
    position: points,
  };
  return await API.airteam3DViewer.updateAreaObject(areaObject);
};

export const deleteAreaObject = async function (id) {
  if (this.sample) return;
  return await API.airteam3DViewer.deleteObject(id);
};

export const removeSelectedArea = function (areaToRemove) {
  const areaObject = this.scene.getObjectById(areaToRemove.plane.id);
  for (let panel of areaToRemove.panels) {
    const panelObject = this.scene.getObjectById(panel.plane.id);
    panelObject.visible = false;
  }
  for (let point of areaToRemove.points) {
    const pointObject = this.scene.getObjectById(point.id);
    pointObject.visible = false;
  }
  for (let line of areaToRemove.lines) {
    const lineObject = this.scene.getObjectById(line.line.id);
    const midPointObject = this.scene.getObjectById(line.midPoint.id);
    lineObject.visible = false;
    midPointObject.visible = false;
  }
  this.areas = this.areas.filter(
    (area) => !area.plane || area.plane.id !== areaToRemove.plane.id
  );
  areaObject.visible = false;

  this.deleteAreaObject(areaToRemove.id);

  this.closeFloatingMenuForArea();

  this.undoStack.push({ action: "DELETE_AREA", area: areaToRemove });
  this.resetRedoStack();
};

export const dragStarted = function (e) {
  const draggedPoint = e.object;
  draggedPoint.lastPosition = {
    x: draggedPoint.position.x,
    y: draggedPoint.position.y,
    z: draggedPoint.position.z,
  };
  draggedPoint.originalPosition = {
    x: draggedPoint.position.x,
    y: draggedPoint.position.y,
    z: draggedPoint.position.z,
  };
  // hide menu while dragging
  this.positionUpdated = false;
};

export const dragEnded = function (e) {
  const draggedPoint = e.object;

  const cameraPosition = this.camera.position.clone();
  const pointPosition = draggedPoint.position.clone();
  const rayDirection = pointPosition.sub(cameraPosition).normalize();

  this.raycaster.set(cameraPosition, rayDirection);
  const intersects = this.raycaster.intersectObject(
    this.modelObject.children[0]
  );

  if (intersects.length < 1) {
    draggedPoint.position.x = draggedPoint.lastPosition.x;
    draggedPoint.position.y = draggedPoint.lastPosition.y;
    draggedPoint.position.z = draggedPoint.lastPosition.z;

    this.reDrawAreaFromPoint(e);
  }
  let area = this.areas[this.areas.length - 1];

  if (this.pointBelongsToArea(draggedPoint, area))
    this.undoStack.push({
      action: "MOVE_POINT",
      areaType: "MOUNTING",
      area: area,
      point: draggedPoint,
    });

  this.updateAreaObject(
    this.selectedArea.id,
    this.selectedArea.points.map((point) => {
      return {
        x: point.position.x,
        y: point.position.y,
        z: point.position.z,
      };
    })
  );

  this.resetRedoStack();

  // show menu when dragging is over
  this.positionUpdated = true;
};

export const dragMidPointEnded = function (e) {
  this.currentArea.midPointAdded = false;
  const removedLine = this.currentArea.lines.splice(
    this.currentArea.lineIndexToRemove,
    1
  )[0];

  const draggedPoint = e.object;

  const cameraPosition = this.camera.position.clone();
  const pointPosition = draggedPoint.position.clone();
  const rayDirection = pointPosition.sub(cameraPosition).normalize();

  this.raycaster.set(cameraPosition, rayDirection);

  const intersects = this.raycaster.intersectObject(
    this.modelObject.children[0]
  );

  if (intersects.length < 1) {
    draggedPoint.position.x = draggedPoint.prevPosition.x;
    draggedPoint.position.y = draggedPoint.prevPosition.y;
    draggedPoint.position.z = draggedPoint.prevPosition.z;

    this.reDrawAreaFromPoint(draggedPoint);
  }

  const sceneObject = this.scene.getObjectById(draggedPoint.id);
  this.scene.remove(sceneObject.parent);

  const newPoint = this.createReactivePoint(draggedPoint.position, false);
  this.scene.add(newPoint);

  for (let i = 0; i < this.currentArea.points.length; i++) {
    const point = this.currentArea.points[i];
    if (point.uuid === draggedPoint.uuid) {
      this.currentArea.points[i] = newPoint;
    }
  }
  for (let line of this.currentArea.lines) {
    if (line.firstPoint.uuid === draggedPoint.uuid) line.firstPoint = newPoint;
    if (line.secondPoint.uuid === draggedPoint.uuid)
      line.secondPoint = newPoint;
  }

  this.disablePointDragMode();
  this.enablePointDragMode();
  document.removeEventListener(
    "mousemove",
    this.detectModelIntersection,
    false
  );

  this.updateAreaObject(
    this.currentArea.id,
    this.currentArea.points.map((point) => {
      return {
        x: point.position.x,
        y: point.position.y,
        z: point.position.z,
      };
    })
  );

  this.positionUpdated = true;

  // Save state for undo
  const newLines = this.currentArea.lines.filter(
    (line) =>
      line.firstPoint.id === newPoint.id || line.secondPoint.id === newPoint.id
  );
  let area = this.areas[this.areas.length - 1];
  this.undoStack.push({
    action: "MOVE_MID_POINT",
    area: area,
    removedLine: removedLine,
    newLine1: newLines[0],
    newLine2: newLines[1],
    newPoint: newPoint,
  });
  this.resetRedoStack();
};

export const enablePointDragMode = function () {
  if (this.selectedArea.panels.length > 0) return;
  if (this.selectedArea.dragControls) {
    this.selectedArea.dragControls.dispose();
    this.selectedArea.midPointDragControls.dispose();
  }
  this.selectedArea.dragControls = new DragControls(
    this.selectedArea.points,
    this.camera,
    this.renderer.domElement
  );
  this.selectedArea.midPointDragControls = new DragControls(
    this.selectedArea.lines.map((line) => line.midPoint),
    this.camera,
    this.renderer.domElement
  );
  this.selectedArea.dragControls.addEventListener("drag", this.dragAreaPoint);
  this.selectedArea.dragControls.addEventListener(
    "dragstart",
    this.dragStarted
  );
  this.selectedArea.dragControls.addEventListener("dragend", this.dragEnded);
  this.selectedArea.midPointDragControls.addEventListener(
    "drag",
    this.dragAreaMidPoint
  );
  this.selectedArea.midPointDragControls.addEventListener(
    "dragstart",
    this.dragStarted
  );
  this.selectedArea.midPointDragControls.addEventListener(
    "dragend",
    this.dragMidPointEnded
  );
};

export const disablePointDragMode = function () {
  if (this.selectedArea && this.selectedArea.dragControls) {
    this.selectedArea.dragControls.deactivate();
    this.selectedArea.midPointDragControls.deactivate();

    this.selectedArea.dragControls.removeEventListener(
      "drag",
      this.dragAreaPoint
    );
    this.selectedArea.dragControls.removeEventListener(
      "dragstart",
      this.dragStarted
    );
    this.selectedArea.dragControls.removeEventListener(
      "dragend",
      this.dragEnded
    );

    this.selectedArea.midPointDragControls.removeEventListener(
      "drag",
      this.dragAreaMidPoint
    );
    this.selectedArea.midPointDragControls.removeEventListener(
      "dragstart",
      this.dragStarted
    );
    this.selectedArea.midPointDragControls.removeEventListener(
      "dragend",
      this.dragMidPointEnded
    );
  }
};

export const calculateAreaAngle = function (geometry, material) {
  const scene = new THREE.Scene();
  const camera = new THREE.PerspectiveCamera(
    75,
    window.innerWidth / window.innerHeight,
    0.1,
    1000
  );

  const cube = new THREE.Mesh(geometry, material);

  scene.add(cube);
  camera.position.z = 5;

  const faceIndex = 0;

  const normal = new THREE.Vector3();
  const a = new THREE.Vector3();
  const b = new THREE.Vector3();
  const c = new THREE.Vector3();

  const positionAttribute = geometry.getAttribute("position");
  const indexAttribute = geometry.getIndex();

  a.fromBufferAttribute(positionAttribute, indexAttribute.array[faceIndex * 3]);
  b.fromBufferAttribute(
    positionAttribute,
    indexAttribute.array[faceIndex * 3 + 1]
  );
  c.fromBufferAttribute(
    positionAttribute,
    indexAttribute.array[faceIndex * 3 + 2]
  );

  normal.subVectors(c, b).cross(b.sub(a)).normalize();

  const referenceVector = new THREE.Vector3(0, 1, 0);

  const angle = normal.angleTo(referenceVector);

  const angleInRadians =
    normal.dot(referenceVector) < 0 ? Math.PI - angle : angle;

  const angleInDegrees = angleInRadians * (180 / Math.PI);

  const roundedAngleInDegrees = Math.round(angleInDegrees);

  return roundedAngleInDegrees;
};

export const removePoints = function (points) {
  for (let point of points) {
    const object = this.scene.getObjectById(point.id);
    this.scene.remove(object);
  }
};

export const detectAreaIntersection = function () {
  let intersects = this.raycaster.intersectObjects(
    this.areas.filter((area) => area.plane).map((area) => area.plane)
  );
  if (intersects.length > 0) return true;
  return false;
};

export const dragAreaPoint = function (event) {
  this.dragOn = true;
  const draggedPoint = event.object;

  const mergedPoint = this.checkMergePoints(draggedPoint, false);
  this.reDrawAreaFromPoint(mergedPoint);
  setTimeout(() => (this.dragOn = false));
};

export const dragAreaMidPoint = function (e) {
  this.dragOn = true;

  const draggedPoint = e.object;
  let otherPoint = null;

  this.currentArea = this.selectedArea;
  const lines = this.currentArea.lines;

  for (let i = 0; i < lines.length; i++) {
    const line = lines[i];
    const pointGroup = line.midPoint;
    const [centerDot, wrapper] = pointGroup.children;
    if (draggedPoint.uuid === centerDot.uuid) {
      otherPoint = wrapper;
    } else if (draggedPoint.uuid === wrapper.uuid) {
      otherPoint = centerDot;
    }
    if (otherPoint) {
      otherPoint.position.x = draggedPoint.position.x;
      otherPoint.position.y = draggedPoint.position.y;
      otherPoint.position.z = draggedPoint.position.z;

      if (!this.currentArea.midPointAdded) {
        line.line.visible = false;

        for (let j = 0; j < this.currentArea.points.length; j++) {
          const point = this.currentArea.points[j];
          if (point.uuid === line.firstPoint.uuid) {
            const newPointIndex = j + 1;
            this.currentArea.points.splice(newPointIndex, 0, draggedPoint);
            this.currentArea.midPointAdded = true;
            break;
          }
        }

        this.currentArea.lineIndexToRemove = i;

        const firstLine = this.createLineGroup(
          line.firstPoint,
          draggedPoint,
          false
        );
        const secondLine = this.createLineGroup(
          draggedPoint,
          line.secondPoint,
          false
        );

        this.currentArea.lines.push(firstLine);
        this.currentArea.lines.push(secondLine);
      } else {
        this.reDrawAreaFromPoint(draggedPoint);
      }
      setTimeout(() => (this.dragOn = false));
      return;
    }
  }
  setTimeout(() => (this.dragOn = false));
};

export const reDrawAreaFromPoint = function (point) {
  let selectedArea = null;
  for (let area of this.areas) {
    for (let i = 0; i < area.points.length; i++) {
      let areaPoint = area.points[i];
      if (areaPoint.uuid === point.uuid) {
        selectedArea = area;
        break;
      }
    }
  }

  for (let i = 0; i < selectedArea.points.length; i++) {
    if (selectedArea.points[i].uuid === point.uuid)
      selectedArea.points[i] = point;
  }

  const projectedPoints = this.projectPointsOnPlane(
    selectedArea.points,
    selectedArea.trianglePlane
  );

  const vertices = new Float32Array(projectedPoints);

  const triangleIndices = this.getTriangleIndices(
    selectedArea.points,
    this.getAxisDifferences(selectedArea.points.map((point) => point.position))
  );
  const indices = [].concat(...triangleIndices);

  selectedArea.plane.geometry.setAttribute(
    "position",
    new THREE.BufferAttribute(vertices, 3)
  );
  selectedArea.plane.geometry.setIndex(
    new THREE.Uint16BufferAttribute(indices, 1)
  );
  selectedArea.plane.geometry.computeBoundingBox();
  selectedArea.plane.geometry.computeBoundingSphere();

  selectedArea.indices = indices;

  const linesToUpdate = selectedArea.lines.filter(
    (line) =>
      line.firstPoint.uuid === point.uuid ||
      line.secondPoint.uuid === point.uuid
  );

  for (let line of linesToUpdate) {
    if (line.firstPoint.uuid === point.uuid) line.firstPoint = point;
    else line.secondPoint = point;

    const firstPoint = new THREE.Vector3();
    const secondPoint = new THREE.Vector3();
    line.firstPoint.getWorldPosition(firstPoint);
    line.secondPoint.getWorldPosition(secondPoint);
    let points = [firstPoint, secondPoint];
    this.updateLinePosition(line.line, points);

    const midPoint = line.midPoint;
    this.updateMidPointPosition(midPoint, firstPoint, secondPoint);

    const cameraPosition = this.camera.position.clone();
    const pointPosition = point.position.clone();
    const rayDirection = pointPosition.sub(cameraPosition).normalize();

    this.raycaster.set(cameraPosition, rayDirection);

    const intersects = this.raycaster.intersectObject(
      this.modelObject.children[0]
    );

    if (intersects.length > 0) {
      point.prevPosition = {
        x: point.position.x,
        y: point.position.y,
        z: point.position.z,
      };
    }
  }

  return selectedArea;
};

export const calculateSurfaceArea = function (area) {
  if (!area.closed) return 0;

  const points = area.points;
  const areaVertices = [];
  for (let i = 0; i < points.length; i++) {
    areaVertices.push(
      new THREE.Vector3(
        points[i].position.x,
        points[i].position.y,
        points[i].position.z
      )
    );
  }

  let surfaceArea = 0;

  const triangles = [];
  for (let i = 0; i < area.indices.length; i++) {
    if (triangles.length > 0 && triangles[triangles.length - 1].length < 3) {
      triangles[triangles.length - 1].push(areaVertices[area.indices[i]]);
    } else {
      triangles.push([areaVertices[area.indices[i]]]);
    }
  }

  // Triangulate the points to form individual triangles
  for (let triangle of triangles) {
    const [v1, v2, v3] = triangle;

    // Calculate the area of the triangle formed by the vertices
    const triangleArea = this.calculateTriangleArea(v1, v2, v3);

    // Add the triangle area to the total surface area
    surfaceArea += triangleArea;
  }

  return surfaceArea.toFixed(2);
};

export const detectSolarAreaIntersection = function (event, persist = false) {
  this.setMousePosition(event);

  const solarObjects = [];
  this.areas
    .filter((area) => area.closed)
    .forEach((area) => {
      solarObjects.push(area.plane);
      area.points.forEach((point) => {
        solarObjects.push(point);
      });
      area.lines.forEach((line) => {
        solarObjects.push(line.midPoint);
        solarObjects.push(line.line);
      });
    });
  let intersects = this.raycaster.intersectObjects(solarObjects);

  if (intersects.length > 0) {
    this.changeCursorToPointer();
  } else {
    this.changeCursorToCrosshair();
  }
};

export const removeLastPoint = function (event) {
  const key = event.key;
  if (key !== "Backspace" && key !== "Delete") return;

  if (this.areas.length === 0) return;
  const currentArea = this.areas[this.areas.length - 1];
  if (currentArea.points.length === 0 || currentArea.closed) return;
  if (currentArea.points.length === 1) {
    this.removeUnfinshedArea();
  } else {
    this.undo();
  }
};

export const getAreaFromPoint = function (point) {
  for (let area of this.areas) {
    for (let i = 0; i < area.points.length; i++) {
      let areaPoint = area.points[i];
      if (areaPoint.uuid === point.uuid) return area;
    }
  }
};
