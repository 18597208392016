import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import API from "@/api/API";
import Papa from "papaparse";

export const loadCamera = function () {
  const loader = new GLTFLoader();
  loader.setPath("/assets/model/");
  loader.load("camera.glb", (gltf) => {
    this.cameraObject = gltf.scene.children[0];
  });
};

export const createCameraInstancedMesh = function (instanceCount) {
  const cameraGeometry = this.cameraObject.geometry;
  const cameraMaterial = this.cameraObject.material;
  const instancedMesh = new THREE.InstancedMesh(
    cameraGeometry,
    cameraMaterial,
    instanceCount
  );
  instancedMesh.visible = false;
  this.scene.add(instancedMesh);

  return instancedMesh;
};

export const transformCamera = function (row) {
  const finalMatrix = new THREE.Matrix4();
  const positionMatrix = new THREE.Vector3();
  const quaternionMatrix = new THREE.Quaternion();
  const scaleMatrix = new THREE.Vector3(5, 5, 5);

  positionMatrix.set(row.x, row.y, row.alt);

  const pitchRadians = THREE.MathUtils.degToRad(row.pitch);
  const headingRadians = THREE.MathUtils.degToRad(row.heading);
  const rollRadians = THREE.MathUtils.degToRad(row.roll);
  quaternionMatrix.setFromEuler(
    new THREE.Euler(pitchRadians, rollRadians - 5, -headingRadians, "ZXY")
  );

  finalMatrix.compose(positionMatrix, quaternionMatrix, scaleMatrix);

  finalMatrix.multiply(this.cameraObject.matrix);

  return finalMatrix;
};

export const fetchCameras = async function () {
  const project = await API.projects.getProject(this.projectId);
  const cameraPositionsCsvUrl = project?.data?.fastFusionModel?.camPosUrl;

  try {
    const response = await fetch(cameraPositionsCsvUrl);
    if (!response.ok) {
      throw new Error("Failed to fetch the CSV file.");
    }

    const csvText = await response.text();
    Papa.parse(csvText, {
      header: true,
      dynamicTyping: true,
      complete: (results) => {
        this.cameraMesh = this.createCameraInstancedMesh(results.data.length);

        results.data.forEach((row, i) => {
          if (
            row.x &&
            row.y &&
            row.alt &&
            row.pitch &&
            row.heading &&
            row.roll
          ) {
            const cameraMatrix = this.transformCamera(row);
            this.cameraMesh.setMatrixAt(i, cameraMatrix);
          }
        });
        this.cameraMesh.instanceMatrix.needsUpdate = true;
      },
    });
  } catch (error) {
    console.error("Error fetching or parsing CSV:", error);
  }
};

export const displayCameras = async function () {
  if (!this.cameraMesh) {
    await this.fetchCameras();
  }
  this.changeCamerasVisibility(true);
};

export const changeCamerasVisibility = function (visible) {
  this.cameraMesh.visible = visible;

  if (visible && this.cameraMesh) {
    this.cameraControls.dollyTo(120, true);
  }
};
