export function initializeModes(vm) {
  return [
    {
      name: "SELECT",
      value: 0,
      enabled: vm.active === 0,
      enableFunction: function () {
        vm.enableMeasurementPointDragMode();
        vm.restoreDefaultNavigation();
        document.addEventListener("click", vm.selectMeasurementArea, false);
        window.addEventListener("dblclick", vm.addZoomCenter, false);
        document.addEventListener(
          "mousemove",
          vm.detectMeasurementIntersection,
          false
        );
        document.addEventListener(
          "mousemove",
          vm.detectAuxiliaryMeasurementIntersection,
          false
        );
        document.addEventListener(
          "click",
          vm.detectPersistentMeasurementIntersection,
          false
        );
      },
      disableFunction: function () {
        vm.disableMeasurementPointDragMode();
        document.removeEventListener("click", vm.selectMeasurementArea, false);
        window.removeEventListener("dblclick", vm.addZoomCenter, false);
        document.removeEventListener(
          "mousemove",
          vm.detectMeasurementIntersection,
          false
        );
        document.removeEventListener(
          "click",
          vm.detectPersistentMeasurementIntersection,
          false
        );
        document.removeEventListener(
          "mousemove",
          vm.detectAuxiliaryMeasurementIntersection,
          false
        );
      },
    },
    {
      name: "DISTANCE_MEASUREMENT",
      value: 1,
      enabled: vm.active === 1,
      enableFunction: function () {
        document.addEventListener("click", vm.addMarker, false);
        document.addEventListener(
          "mousemove",
          vm.detectModelIntersection,
          false
        );
        document.addEventListener("keydown", vm.removeLastDistancePoint, false);
      },
      disableFunction: function () {
        document.removeEventListener("click", vm.addMarker, false);
        document.removeEventListener(
          "mousemove",
          vm.detectModelIntersection,
          false
        );
        document.removeEventListener(
          "keydown",
          vm.removeLastDistancePoint,
          false
        );
        vm.removeUnfinishedMeasurements();
      },
    },
    {
      name: "SOLAR_AREA",
      value: 2,
      enabled: vm.active === 2,
      enableFunction: function () {
        document.addEventListener("click", vm.addPoint, false);
        document.addEventListener(
          "mousemove",
          vm.detectModelIntersection,
          false
        );
        document.addEventListener(
          "mousemove",
          vm.detectSolarAreaIntersection,
          false
        );
        document.addEventListener("keydown", vm.removeLastPoint, false);
        window.addEventListener("mousemove", vm.stickMousePointerToDot);
        vm.showAreas();
      },
      disableFunction: function (nextMode) {
        window.removeEventListener("mousemove", vm.stickMousePointerToDot);
        vm.clearMagnetEffect();
        if (nextMode === 2 || nextMode === 3) return;
        document.removeEventListener("click", vm.addPoint, false);
        document.removeEventListener(
          "mousemove",
          vm.detectModelIntersection,
          false
        );
        document.removeEventListener(
          "mousemove",
          vm.detectSolarAreaIntersection,
          false
        );
        document.removeEventListener("keydown", vm.removeLastPoint, false);
        vm.cleanPanelSetup();
        vm.disablePointDragMode();
        vm.removeUnfinshedArea();

        if (nextMode !== 5) {
          vm.selectedArea = null;
          vm.areas
            .filter((area) => area.plane)
            .forEach((area) => (area.plane.material.opacity = 0.5));
          vm.hideAreas();
        }
      },
    },
    {
      name: "ADD_PANELS",
      value: 3,
      enabled: vm.active === 3,
      enableFunction: function () {
        vm.cleanPanelSetup();
      },
      disableFunction: function (nextMode) {
        this.disablePointDragMode();
        if (nextMode === 2)
          document.addEventListener("click", this.addPoint, false);

        if (nextMode === 5) return;
        this.selectedArea = null;
        this.areas
          .filter((area) => area.plane)
          .forEach((area) => (area.plane.material.opacity = 0.5));
        this.positionUpdated = false;
      },
    },
    {
      name: "AREA_MEASUREMENT",
      value: 4,
      enabled: vm.active === 4,
      enableFunction: function () {
        document.addEventListener("click", vm.addMeasurementPoint, false);
        document.addEventListener(
          "mousemove",
          vm.stickMousePointerToDotForMeasurementArea,
          false
        );
        document.addEventListener(
          "mousemove",
          vm.detectModelIntersection,
          false
        );
        document.addEventListener(
          "keydown",
          vm.removeLastMeasurementPoint,
          false
        );
      },
      disableFunction: function () {
        document.removeEventListener("click", vm.addMeasurementPoint, false);
        document.removeEventListener(
          "mousemove",
          vm.stickMousePointerToDotForMeasurementArea,
          false
        );
        document.removeEventListener(
          "mousemove",
          vm.detectModelIntersection,
          false
        );
        document.removeEventListener(
          "keydown",
          vm.removeLastMeasurementPoint,
          false
        );
        vm.removeUnfinishedAreaMeasurements();
        vm.clearMagnetEffect();
      },
    },
    {
      name: "EDIT_PANELS",
      value: 5,
      enabled: vm.active === 5,
      enableFunction: function () {
        this.editPanelMode = true;
        this.disableDefaultNavigation();
        document.addEventListener("click", vm.selectPanel, false);
        document.addEventListener("keydown", vm.deleteSelectedPanel, false);
        document.addEventListener("pointerdown", vm.onMouseDownFrame, false);
        document.addEventListener("pointermove", vm.onMouseMoveFrame, false);
        document.addEventListener("pointerup", vm.onMouseUpFrame, false);
      },
      disableFunction: function () {
        this.editPanelMode = false;
        this.unselectPanels();
        document.removeEventListener("click", vm.selectPanel, false);
        document.removeEventListener("keydown", vm.deleteSelectedPanel, false);
        document.removeEventListener("pointerdown", vm.onMouseDownFrame, false);
        document.removeEventListener("pointermove", vm.onMouseMoveFrame, false);
        document.removeEventListener("pointerup", vm.onMouseUpFrame, false);
      },
    },
  ];
}
