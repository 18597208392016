import { createStore } from "vuex";
import VuexPersist from "vuex-persist";
import auth from "@/store/modules/auth";
import customPanels from "@/store/modules/customPanels";

const persist = new VuexPersist({
  key: "ffsolar",
  storage: localStorage,
});

export default createStore({
  modules: {
    auth,
    customPanels,
  },
  plugins: [persist.plugin],
});
